import {createSlice,createAsyncThunk} from '@reduxjs/toolkit' 
import authService from './authService'

const user=JSON.parse(localStorage.getItem('user'))
const initialState={
    user: user?user:null,
    isError:false,
    isLoading:false,
    isSuccess:false,
    message:''
}

export const register=createAsyncThunk('auth/register',
async(user,thunkAPI)=>{
    try {
      return await authService.register(user)  
    } catch (error) {
        const message=(error.response && error.response.data 
        && error.response.data.message) ||
        error.message ||
        error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const login=createAsyncThunk('auth/login',
async(user,thunkAPI)=>{
    try {
        return await authService.login(user)  
      } catch (error) {
          const message=(error.response && error.response.data 
          && error.response.data.message) ||
          error.message ||
          error.toString()
          return thunkAPI.rejectWithValue(message)
      }
})

export const logOut=createAsyncThunk('auth/logout',
async()=>{
    await authService.logOut()
})

export const updateUser=createAsyncThunk('auth/update',
async(userData,thunkAPI)=>{
    try {
       
        const state=thunkAPI.getState().auth
        const token=state.user.token
        return await authService.updateUser(userData,token)
    } catch (error) {
        const message=(error.response && error.response.data 
            && error.response.data.message) ||
            error.message ||
            error.toString()
            return thunkAPI.rejectWithValue(message)
    }
})

export const authSlice=createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset: (state)=>{
            state.isLoading=false
            state.isError=false
            state.isSuccess=false
            state.message=''
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(register.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(register.fulfilled,(state,action)=>{
            state.isLoading=false
            state.isSuccess=true
            state.user=action.payload
        })
        .addCase(register.rejected,(state,action)=>{
            state.isLoading=false
            state.user=null
            state.isError=true
            state.message=action.payload
        })
        .addCase(logOut.fulfilled,(state)=>{
            state.user=null
        })
        .addCase(login.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(login.fulfilled,(state,action)=>{
            state.isLoading=false
            state.isSuccess=true
            state.user=action.payload
        })
        .addCase(login.rejected,(state,action)=>{
            state.isLoading=false
            state.user=null
            state.isError=true
            state.message=action.payload
        })
        .addCase(updateUser.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(updateUser.fulfilled,(state,action)=>{
            state.isLoading=false
            state.isSuccess=true
            state.user={
                ...state.user,
                ...action.payload
            }
        })
        .addCase(updateUser.rejected,(state,action)=>{
            state.isLoading=false
            state.isError=true
            state.message=action.payload
        })
    }
})

export const {reset}=authSlice.actions
export default authSlice.reducer